<template>
  <div class="box">
    <div class="profile-header" style="padding-top:0">
      <div class="container">
        <div class="header-cards">
          <div class="row">
            <div class="col-md-6">
              <div class="cards-item" style="height:300px;margin-top:20px">
                <div class="section-title">
                  <div>{{ showTitle("QUICK STATS") }}</div>
                </div>
                <hr/>
                <div class="section-title" style="opacity: 0.8">
                  <div>{{ showTitle("Location :") }}</div>
                  <div>
                    {{ showReport(getData.ao_city) }}
                  </div>
                </div>
                <div class="section-title" style="opacity: 0.8">
                  <div>{{ showTitle("Avg Low in January :") }}</div>
                  <div>
                    {{ showReport(toThousands(getData.temp_low), "°F") }}
                  </div>
                </div>
                <div class="section-title" style="opacity: 0.8">
                  <div>{{ showTitle("Avg High in September :") }}</div>
                  <div>
                    {{ showReport(toThousands(getData.temp_high), "°F") }}
                  </div>
                </div>
                <div class="section-title" style="opacity: 0.8">
                  <div>{{ showTitle("Rainy Days / Year") }}</div>
                  <div>
                    {{ showReport(toThousands(getData.rain_days)) }}
                    {{ showTitle("days") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="locationMap">
                <baidu-map   :center="center" :zoom="zoom" :scroll-wheel-zoom='true' class="bmView" ak="BnpYvwZd3AYaD5GGqSC7ywAXEZDkcDay">
                    <bm-marker :position="center" :dragging="false" :title="name" animation="BMAP_ANIMATION_BOUNCE">
                    </bm-marker>
                </baidu-map>
                <div class="locationAddress">
                  <div class="section-title">
                    <div>{{ showTitle("School Location") }}</div>
                  </div>
                  <hr />
                  <div style="margin-top:20px">
                    <h6><small style="color:red;margin-right:5px;font-size:16px"><i class="fas fa-map-marker"></i></small>{{name}}</h6>
                    <div>
                      <div>{{ showReport(getData.ao_address) }}</div>
                      <div>
                        {{ showReport(getData.ao_city) }},
                        {{ showReport(getData.ao_state) }}, {{ getData.ao_zipcode1 }}-{{
                          getData.ao_zipcode2
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="section-title">
        {{ showTitle("LOCATION AND SETTING") }}
      </div>
      <div class="content">
        <div class="content-title">
          {{ showReport(getData.ao_city) }} {{ showTitle("Population") }}
        </div>
        <div class="content-title-value">
          {{ showReport(toThousands(getData.city_population)) }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Nearest Metropolitan Area") }}
        </div>
        <div class="content-title-value">
          {{ showReport(getData.nearest_metro) }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Campus Size") }}</div>
        <div class="content-title-value">
          {{ showReport(toThousands(getData.campus_size_acre)) }} acres
        </div>
      </div>
      <hr class="solid" />
      <div class="section-subtitle">
        {{ showTitle("WEATHER") }}
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Avg Low In Jan") }}</div>
        <div class="content-title-value">
          {{ showReport(toThousands(getData.temp_low), "°F") }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Avg High In Sep") }}</div>
        <div class="content-title-value">
          {{ showReport(toThousands(getData.temp_high), "°F") }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Rainy Days / Year") }}</div>
        <div class="content-title-value">
          {{ showReport(toThousands(getData.rain_days)) }}
          {{ showTitle("days") }}
        </div>
      </div>
      <hr class="solid" />
      <div class="section-subtitle">
        {{ showTitle("GETTING AROUND") }}
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Campus Map") }}</div>
        <div class="content-title-value">
          <a :href="getData.map_url" target="_blank"> View Map</a>
        </div>
      </div>
      <div class="content" v-if="getData.nearest_bus_dist_mi">
        <div class="content-title">{{ showTitle("Nearest Bus Station") }}</div>
        <div class="content-title-value">
          {{ getData.nearest_bus_dist_mi }} mile(s) from campus in
          {{ getData.ao_city }}
        </div>
      </div>
      <div class="content" v-if="getData.nearest_train_dist_mi">
        <div class="content-title">
          {{ showTitle("Nearest Train Station") }}
        </div>
        <div class="content-title-value">
          {{ getData.nearest_train_dist_mi }} mile(s) from campus in
          {{ getData.ao_city }}
        </div>
      </div>
      <hr />
      <div class="section-title">
        {{ showTitle("HOUSING") }}
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("College Housing") }}</div>
        <div class="content-title-value">
          <div v-if="getData.has_housing === 1">
            College offers housing to students
          </div>
          <div v-if="getData.has_housing === 0">
            College does not provide housing to students
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Types of Housing") }}</div>
        <div class="content-title-value">
          {{ showReport(getData.housing_type) }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Students in College Housing") }}
        </div>
        <div class="content-title-value">
          {{ showReport(getData.housing_pct, "%") }} of all students
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Housing Requirements") }}</div>
        <div class="content-title-value">
          {{ showReport(getData.housing_requirement) }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Freshman Housing Guarantee") }}
        </div>
        <div class="content-title-value">
          {{ showReport(getData.housing_guarantee) }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Students Living Off Campus/Commuting") }}
        </div>
        <div class="content-title-value">
          {{ showReport(getData.offcampus_pct, "%") }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Off-Campus Housing Assistance") }}
        </div>
        <div class="content-title-value">
          {{ showReport(getData.offcampus_assistance) }}
        </div>
      </div>
      <hr />
      <div class="section-title">
        {{ showTitle("SECURITY") }}
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("24Hour Emergency Phone/Alarm Devices") }}
        </div>
        <div class="content-title-value">
          <div v-if="getData.has_alarm_devices === 1">
            {{ $t("schools.Available") }}
          </div>
          <div v-if="getData.has_alarm_devices === 0">
            {{ $t("schools.Not Available") }}
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("24Hour Security Patrols") }}
        </div>
        <div class="content-title-value">
          <div v-if="getData.has_security === 1">
            {{ $t("schools.Available") }}
          </div>
          <div v-if="getData.has_security === 0">
            {{ $t("schools.Not Available") }}
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Late-Night Transport/Escort Services") }}
        </div>
        <div class="content-title-value">
          <div v-if="getData.has_escort === 1">
            {{ $t("schools.Available") }}
          </div>
          <div v-if="getData.has_escort === 0">
            {{ $t("schools.Not Available") }}
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          {{ showTitle("Electronically Operated Housing Entrances") }}
        </div>
        <div class="content-title-value">
          <div v-if="getData.has_e_entrance === 1">
            {{ $t("schools.Available") }}
          </div>
          <div v-if="getData.has_e_entrance === 0">
            {{ $t("schools.Not Available") }}
          </div>
        </div>
      </div>
      <hr />
      <div class="section-title">
        {{ showTitle("PERSONAL SUPPORT SERVICES") }}
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Health Service") }}</div>
        <div class="content-title-value">
          <div v-if="getData.has_health_serv === 1">
            {{ $t("schools.Offered") }}
          </div>
          <div v-if="getData.has_health_serv === 0">
            {{ $t("schools.Not offered") }}
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Personal Counseling") }}</div>
        <div class="content-title-value">
          <div v-if="getData.has_counseling === 1">
            {{ $t("schools.Offered") }}
          </div>
          <div v-if="getData.has_counseling === 0">
            {{ $t("schools.Not offered") }}
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Child Care") }}</div>
        <div class="content-title-value">
          <div v-if="getData.has_childcare === 1">
            {{ $t("schools.Offered") }}
          </div>
          <div v-if="getData.has_childcare === 0">
            {{ $t("schools.Not offered") }}
          </div>
        </div>
      </div>
      <hr />
      <div class="section-title">
        {{ showTitle("SPORTS & RECREATION") }}
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Athletic Conferences") }}</div>
        <div class="content-title-value">
          {{ showReport(getData.athletic_conference) }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Mascot") }}</div>
        <div class="content-title-value">
          {{ showReport(getData.mascot) }}
        </div>
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("School Colors") }}</div>
        <div class="content-title-value">
          {{ showReport(getData.school_colors) }}
        </div>
      </div>
      <hr class="solid" />
      <div class="section-subtitle">
        {{ showTitle("INTERCOLLEGIATE SPORTS & SCHOLARSHIPS") }}
      </div>
      <div class="content-title-value">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th :rowspan="2">SPORTS</th>
              <th :colspan="2">MEN</th>
              <th :colspan="2">WOMEN</th>
            </tr>
            <tr>
              <th>Offered</th>
              <th>Scholarships</th>
              <th>Offered</th>
              <th>Scholarships</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Baseball</td>
              <td>
                <div v-if="getData.has_m_baseball === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_m_baseball_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_baseball === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_baseball_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td>Basketball</td>
              <td>
                <div v-if="getData.has_m_basketball === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_m_basketball_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_basketball_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_basketball_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td>Crew</td>
              <td>
                <div v-if="getData.has_m_crew === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_m_crew_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_crew_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_crew_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td>Cross-Country Running</td>
              <td>
                <div v-if="getData.has_m_cc === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_m_cc_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_cc === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_cc_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td>Fencing</td>
              <td>
                <div v-if="getData.has_m_fencing === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_m_fencing_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_fencing_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_fencing_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td>Field Hockey</td>
              <td>
                <div v-if="getData.has_m_hockey === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_m_hockey_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_hockey_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_hockey_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td>Football</td>
              <td>
                <div v-if="getData.has_m_football === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_m_football_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_football === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_football_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td>Golf</td>
              <td>
                <div v-if="getData.has_m_golf === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_m_golf_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_golf === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_golf_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td>Ice Hockey</td>
              <td>
                <div v-if="getData.has_m_icehockey === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_m_icehockey_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_icehockey === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_icehockey_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td>Lacrosse</td>
              <td>
                <div v-if="getData.has_m_lax === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_m_lax_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_lax === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_lax_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td>Ruby</td>
              <td>
                <div v-if="getData.has_m_ruby === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_m_ruby_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_ruby === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_ruby_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td>Sailing</td>
              <td>
                <div v-if="getData.has_m_sailing === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_m_sailing_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_sailing === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_sailing_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td>Skiing (Cross-Country)</td>
              <td>
                <div v-if="getData.has_m_cc_ski === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_m_cc_ski_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_cc_ski === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_cc_ski_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td>Skiing (Downhill)</td>
              <td>
                <div v-if="getData.has_m_dh_ski === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_m_dh_ski_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_dh_ski === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_dh_ski_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td>Soccer</td>
              <td>
                <div v-if="getData.has_m_soccer === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_m_soccer_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_soccer === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_soccer_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td>Softball</td>
              <td>
                <div v-if="getData.has_m_scftball === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_m_scftball_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_scftball === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_scftball_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td>Suquash</td>
              <td>
                <div v-if="getData.has_m_squash === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_m_squash_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_squash === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_squash_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td>Swimming And Diving</td>
              <td>
                <div v-if="getData.has_m_swim === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_m_swim_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_swim === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_swim_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td>Tennis</td>
              <td>
                <div v-if="getData.has_m_tennis === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_m_tennis_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_tennis === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_tennis_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td>Track And Field</td>
              <td>
                <div v-if="getData.has_m_track === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_m_track_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_track === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_track_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td>Volleyball</td>
              <td>
                <div v-if="getData.has_m_volleyball === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_m_volleyball_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_volleyball === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_volleyball_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td>Water Polo</td>
              <td>
                <div v-if="getData.has_m_wpolo === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_m_wpolo_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_wpolo === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_wpolo_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td>Wrestling</td>
              <td>
                <div v-if="getData.has_m_wrestling === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_m_wrestling_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_wrestling === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
              <td>
                <div v-if="getData.has_w_wrestling_scho === 1">
                  <i class="fas fa-check-square"></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr class="solid" />
      <div class="section-subtitle">
        {{ showTitle("CLUB SPORTS") }}
      </div>
      <div class="content">
        <div class="content-title">{{ showTitle("Intramural Sports") }}</div>
        <div class="content-title-value">
          {{ showReport(getData.im_sports) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../mixins/index.js";
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import BmMarker from "vue-baidu-map/components/overlays/Marker.vue";
import BmLocalSearch from "vue-baidu-map/components/search/LocalSearch.vue";
export default {
  components: {
    BaiduMap,
    BmMarker
  },

  mixins: [mixin],

  props: ["getData", "lang", "theme", "name"],
  data() {
    return {
      center: {lng: -99.707941, lat: 32.47108},
      zoom: 15
    };
  },
  computed: {},
  watch: {},

  created() {
    this.center.lng = this.getData.longitude;
    this.center.lat = this.getData.latitude;
  },

  methods: {
    showTitle(val) {
      return this.$t("schools.Campus Life Section." + this.trimStr(val));
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../css/index.scss";
.locationMap {
  margin-top: 20px;
  display: flex;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
}
.bmView {
  width: 65%;
  height: 300px;
}
.locationAddress {
  width: 35%;
  padding:0 16px 10px;
}
@media screen and (max-width: 768px ) {
  .locationMap {
    flex-direction:column-reverse
  }
  .bmView , .locationAddress {
    width: 100%;
  }
}
</style>
