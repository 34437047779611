<template>
  <div class="school" :style="appStyle" v-if="styleColor.color">
    <div class="school-header">
      <div class="container">
        <h1 class="school-title">
          <!-- {{ school.name[langList[lang.value]] }} -->
          <span v-if="lang.value === 'EN-US'">{{
            school.name[langList[lang.value]]
          }}</span>
          <span v-else
            >{{ school.name[langList[lang.value]] }}&nbsp;({{
              school.name["en"]
            }})</span
          >
          <span class="school-subtitle" v-if="school.ranks.length > 0">
            <!-- PC端 -->
            <div class="d-none d-md-block">
              <el-tag
                type="info"
                class="ranks"
                v-for="rank in school.ranks.slice(0 , 1)"
                :key="rank.id"
              >
                <span class="text-warning" style="margin-right:5px"
                  ><i class="fas fa-tag"></i
                ></span>
                <span>
                  <span v-if="rank.site_name">[{{ rank.site_name }}] </span>
                  <b>#{{ rank.rank }}</b> in
                  <a :href="'/schools?rank=' + rank.id" target="_blank"
                    ><b>{{ rank.rank_year }} {{ rank.rank_name["en"] }}</b></a
                  ></span
                >
              </el-tag>
              <router-link
                :to="{ name: 'School', params: { schoolId, type: 'ranks' } }"
                style="font-size:16px;color:white !important"
              > <el-button type="success" size="small">
                {{ $t("schools.More Rankings") }}
                </el-button>
              </router-link>
            </div>
            <!-- 手机端 -->
            <div class="d-block d-md-none">
              <el-collapse accordion>
                <el-collapse-item>
                  <template slot="title">
                    <span class="text-warning" style="margin-right:5px"
                      ><i class="fas fa-tag"></i
                    ></span>
                    {{ $t("schools.Ranks") }}
                  </template>
                  <div
                    type="info"
                    class="ranks"
                    v-for="rank in school.ranks"
                    :key="rank.id"
                  >
                    <span class="text-warning" style="margin-right:5px"
                      ><i class="fas fa-tag"></i
                    ></span>
                    <span>
                      <span v-if="rank.site_name">[ {{ rank.site_name }} ] </span>
                      <b>#{{ rank.rank }}</b> in
                      <a :href="'/schools?rank=' + rank.id" target="_blank"
                        ><b
                          >{{ rank.rank_year }} {{ rank.rank_name["en"] }}</b
                        ></a
                      ></span
                    >
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </span>
        </h1>
      </div>
    </div>
    <div class="container">
      <!-- PC端 -->
      <div class="d-none d-md-block">
        <el-menu
          :default-active="activeName"
          mode="horizontal"
          @select="viewDetails"
          class=""
        >
          <el-menu-item index="overview">
            <span class="icon-title">
              <i class="fas fa-list"></i>
              {{ $t("schools.Overview") }}
            </span>
          </el-menu-item>
          <el-menu-item index="admissions">
            <span class="icon-title">
              <i class="fas fa-graduation-cap"></i>
              {{ $t("schools.Admissions") }}
            </span>
          </el-menu-item>
          <el-menu-item index="financials">
            <span class="icon-title">
              <i class="fas fa-search-dollar"></i>
              {{ $t("schools.Financials") }}
            </span>
          </el-menu-item>
          <el-menu-item index="academic">
            <span class="icon-title">
              <i class="fas fa-file-alt"></i>
              {{ $t("schools.Academic") }}
            </span>
          </el-menu-item>
          <el-menu-item index="campus-life">
            <span class="icon-title">
              <i class="fas fa-university"></i>
              {{ $t("schools.Campus Life") }}
            </span>
          </el-menu-item>
          <el-menu-item index="students">
            <span class="icon-title">
              <i class="fas fa-users"></i>
              {{ $t("schools.Students") }}
            </span>
          </el-menu-item>
          <el-menu-item index="salaries">
            <span class="icon-title">
              <i class="fas fa-comment-dollar"></i>
              {{ $t("schools.Salaries") }}
            </span>
          </el-menu-item>
          <el-menu-item index="ranks">
            <span class="icon-title">
              <i class="fas fa-chart-bar"></i>
              {{ $t("schools.Ranks") }}
            </span>
          </el-menu-item>
        </el-menu>
      </div>
      <!-- 手机端 -->
      <div class="d-block d-md-none">
        <div class="sections">
          <div class="sections-btns">
            <span
              :class="
                activeName === 'overview' ? 'icon-title active' : 'icon-title'
              "
              @click="viewDetails('overview')"
            >
              <div class="btn-logo">
                <i class="fas fa-list"></i>
              </div>
              {{ $t("schools.Overview") }}
            </span>
            <span
              :class="
                activeName === 'admissions' ? 'icon-title active' : 'icon-title'
              "
              @click="viewDetails('admissions')"
            >
              <div class="btn-logo">
                <i class="fas fa-graduation-cap"></i>
              </div>
              {{ $t("schools.Admissions") }}
            </span>
            <span
              :class="
                activeName === 'financials' ? 'icon-title active' : 'icon-title'
              "
              @click="viewDetails('financials')"
            >
              <div class="btn-logo">
                <i class="fas fa-search-dollar"></i>
              </div>
              {{ $t("schools.Financials") }}
            </span>
            <span
              :class="
                activeName === 'academic' ? 'icon-title active' : 'icon-title'
              "
              @click="viewDetails('academic')"
            >
              <div class="btn-logo">
                <i class="fas fa-file-alt"></i>
              </div>
              {{ $t("schools.Academic") }}
            </span>
            <span
              :class="
                activeName === 'campus-life'
                  ? 'icon-title active'
                  : 'icon-title'
              "
              @click="viewDetails('campus-life')"
            >
              <div class="btn-logo">
                <i class="fas fa-university"></i>
              </div>
              {{ $t("schools.Campus Life") }}
            </span>
            <span
              :class="
                activeName === 'students' ? 'icon-title active' : 'icon-title'
              "
              @click="viewDetails('students')"
            >
              <div class="btn-logo">
                <i class="fas fa-users"></i>
              </div>
              {{ $t("schools.Students") }}
            </span>
            <span
              :class="
                activeName === 'salaries' ? 'icon-title active' : 'icon-title'
              "
              @click="viewDetails('salaries')"
            >
              <div class="btn-logo">
                <i class="fas fa-comment-dollar"></i>
              </div>
              {{ $t("schools.Salaries") }}
            </span>
            <span
              :class="
                activeName === 'ranks' ? 'icon-title active' : 'icon-title'
              "
              @click="viewDetails('ranks')"
            >
              <div class="btn-logo">
                <i class="fas fa-chart-bar"></i>
              </div>
              {{ $t("schools.Ranks") }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="activeName === 'overview'">
      <Overview
        :getData="data"
        :lang="langList[lang.value]"
        :theme="styleColor.theme"
      />
    </div>
    <div v-if="activeName === 'admissions'">
      <Admissions
        :getData="data"
        :lang="langList[lang.value]"
        :theme="styleColor.theme"
        :name="school.name[langList[lang.value]]"
      />
    </div>
    <div v-if="activeName === 'financials'">
      <Financials
        :getData="data"
        :lang="langList[lang.value]"
        :theme="styleColor.theme"
      />
    </div>
    <div v-if="activeName === 'academic'">
      <Academic
        :getData="data"
        :lang="langList[lang.value]"
        :theme="styleColor.theme"
      />
    </div>
    <div v-if="activeName === 'campus-life'">
      <CampusLife
        :getData="data"
        :lang="langList[lang.value]"
        :theme="styleColor.theme"
        :name="school.name[langList[lang.value]]"
      />
    </div>
    <div v-if="activeName === 'students'">
      <Students
        :getData="data"
        :lang="langList[lang.value]"
        :theme="styleColor.theme"
      />
    </div>
    <div v-if="activeName === 'salaries'">
      <Salaries
        :getData="data"
        :lang="langList[lang.value]"
        :theme="styleColor.theme"
      />
    </div>
    <div v-if="activeName === 'ranks'">
      <Ranks
        :getData="data"
        :lang="langList[lang.value]"
        :theme="styleColor.theme"
      />
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import schoolApi from "@/views/schools/apis/school.js";
import Overview from "@/views/schools/components/Overview";
import Admissions from "@/views/schools/components/Admissions";
import Financials from "@/views/schools/components/Financials";
import Academic from "@/views/schools/components/Academic";
import CampusLife from "@/views/schools/components/CampusLife";
import Students from "@/views/schools/components/Students";
import Salaries from "@/views/schools/components/Salaries";
import Ranks from "@/views/schools/components/Ranks";
import _ from "lodash";

export default {
  metaInfo() {
    return {
      title: this.school.name[this.langList[this.lang.value]]
        ? this.school.name[this.langList[this.lang.value]] +
          " - Ivy-Way Academy"
        : "Ivy-Way Academy"
    };
  },

  components: {
    Overview,
    Admissions,
    Financials,
    Academic,
    CampusLife,
    Students,
    Salaries,
    Ranks
  },

  mixins: [],

  props: [],
  data() {
    return {
      schoolId: null,
      school: {
        name: "Abilene Christian University",
        ranks: []
      },
      styleColor: {
        theme: undefined,
        color: undefined
      },
      langList: {
        "ZH-TW": "tw",
        "EN-US": "en",
        "ZH-CN": "cn"
      },
      data: {}
    };
  },
  computed: {
    ...mapState("user", ["token", "lang"]),
    appStyle() {
      return `--theme: ${this.styleColor.theme};--color: ${this.styleColor.color};`;
    },
    activeName() {
      if (this.$route.params.type) {
        return this.$route.params.type;
      } else {
        return "overview";
      }
    }
  },
  watch: {},

  async mounted() {
    let activeName = this.activeName;
    if (this.activeName === "ranks") {
      activeName = "overview";
    }

    await this.setSchoolId();
    this.data = await schoolApi.getSection(this.schoolId, activeName);
    const school = await schoolApi.getSchool(this.schoolId);
    this.school.name = school.overview.name;
    this.school.ranks = _.orderBy(
      school.overview.ranks,
      ["rank", "rank_year", "rank_name.en"],
      ["asc", "desc", "asc"]
    );
    if (school.overview.color !== null && school.overview.color !== "") {
      this.styleColor.theme = "#" + school.overview.color;
      this.styleColor.color = "#" + school.overview.color;
    } else {
      this.styleColor.theme = "#42a16a";
      this.styleColor.color = "#42a16a";
    }
  },

  methods: {
    async setSchoolId() {
      const {
        school: { slug }
      } = await schoolApi.getSchoolCurrentSlug(this.$route.params.schoolId);
      this.schoolId = slug;
      if (this.$route.params.schoolId !== slug) {
        const newPath = this.$route.path.replace(
          this.$route.params.schoolId,
          slug
        );
        this.$router.replace({ path: newPath });
      }
    },
    viewDetails(key) {
      this.$router.replace({
        name: "School",
        params: {
          schoolId: this.schoolId,
          type: key
        }
      });
    }
  }
};
</script>

<style scoped>
.school h1,
.school h2,
.school h3,
.school h4,
.school h5,
.school h6,
.school p {
  color: var(--color);
}
.ranks {
  display: inline-block;
  border: 1px solid #fff;
  font-size: 16px;
  margin: 5px;
}
.container >>> .el-menu--horizontal > .el-menu-item.is-active {
  color: var(--color);
}
.container >>> .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.container >>> .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus {
  color: var(--color);
}
.container >>> .el-menu.el-menu--horizontal {
  border-bottom: none;
  margin-bottom: 2px;
}
.container >>> .el-menu--horizontal > .el-menu-item {
  font-weight: 500;
  padding: 0;
  margin-right: 40px;
}
.school-header {
  padding: 50px 0 20px 0;
  background-color: var(--theme);
}
.school-header >>> .el-tabs__item.is-active {
  color: var(--color);
}
.school-header >>> .el-tabs__nav-wrap::after {
  background-color: var(--theme);
}
.school-header >>> .el-tabs__item:hover {
  color: var(--color);
}
.school-header >>> .el-tabs__active-bar {
  background-color: var(--color);
}
.container >>> .el-menu--horizontal > .el-menu-item.is-active {
  border-color: var(--theme);
}
.school .school-title {
  font-weight: 300;
  color: white;
}
.school-subtitle {
  display: block;
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: 0.63px;
  margin-top: 8px;
}
@media screen and (max-width: 768px) {
  .school-header {
    padding: 20px 0 10px 0;
  }
  h1,
  .h1 {
    font-size: 2rem;
  }
  .ranks {
    width: 100%;
    overflow: auto;
    font-size: 14px;
    margin: 0;
    margin-top: 5px;
    background-color: #f3f3f3;
    border-color: #e6e6e6;
    color: #838383;
    border-radius: 4px;
    padding: 0 10px;
    /* height: 32px; */
  }
  .sections {
    width: 100%;
    overflow: auto;
  }
  .sections-btns {
    margin: 10px 0 5px 0;
    white-space: nowrap;
    color: #909399;
  }
  .icon-title {
    padding-bottom: 5px;
    display: inline-block;
    min-width: 60px;
    font-size: 14px;
    text-align: center;
  }
  .icon-title:not(:last-child) {
    margin-right: 20px;
  }
  .icon-title .btn-logo {
    font-size: 22px;
  }
  .icon-title.active {
    color: var(--theme);
    border-bottom: 2px solid var(--theme);
  }
  .school-subtitle >>> .el-collapse-item__header {
    background-color: var(--theme);
    color: white;
    font-size: 14px;
    height: 30px;
    border: none;
  }
  .school-subtitle >>> .el-collapse {
    border: none;
  }
  .school-subtitle >>> .el-collapse-item__content {
    background-color: var(--theme);
    padding-bottom: 0;
  }
  .school-subtitle >>> .el-collapse-item__wrap {
    border: none;
  }
}
</style>
